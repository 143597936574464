import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text  } from "react-native";
import BotonesTienda from "../components/BotonesTienda";

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 800,
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      buttonPequeno: {
        marginBottom: 5
      }
});

export default function ProductosTienda({navigation}){

    const [editar , SetEditar] = React.useState(0);
    const [nombreProducto , SetNombreProducto] = React.useState('');
    const [descripcionProducto , SetDescripcionProducto] = React.useState('');
    const [precioProducto , SetPrecioProducto] = React.useState('');

    return (
        <View style={{flex: 1}}>
            <ScrollView style={styles.container}>
                <Text style={styles.titulo}>Productos</Text>
                { editar ? <>
                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetEditar(0)}>
                        <Text style={styles.buttonPequenoTexto} >Volver al listado</Text>
                    </TouchableOpacity>
                    <TextInput 
                        style={styles.input}
                        onChangeText={SetNombreProducto}
                        value={precioProducto}
                        placeholder="Nombre del Producto"
                    />
                    <TextInput 
                        style={styles.input}
                        onChangeText={SetPrecioProducto}
                        value={nombreProducto}
                        placeholder="Nombre del Producto"
                    />
                    <TextInput 
                        multiline
                        numberOfLines={4}
                        style={styles.input}
                        onChangeText={SetDescripcionProducto}
                        value={descripcionProducto}
                        placeholder="Descripción del Producto"
                    />

                    <TouchableOpacity style={styles.buttonGrande} onPress={() => SetEditar(1)}>
                        <Text style={styles.buttonGrandeTexto} >Crear Producto</Text>
                    </TouchableOpacity>
                </> :  <>
                    <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetEditar(1)}>
                        <Text style={styles.buttonPequenoTexto} >Crear Producto</Text>
                    </TouchableOpacity>
                </> }
            </ScrollView>

            <BotonesTienda navigation={navigation}/>
        </View>
    );
}