import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { doc, getDoc } from "firebase/firestore";
import Firebase from "../db/Firebase";
import Botones from "../components/Botones";

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    titulo: {
        fontWeight: 800,
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
});

function LogoutFn()
{
    AsyncStorage.setItem('authToken', '');
    AsyncStorage.setItem('tipoUser', '');
    window.location.reload(false);
}

const authToken = await AsyncStorage.getItem('authToken');console.log(authToken);
const docRef = authToken == '' || authToken == null ? null : doc(Firebase , 'users' , authToken);console.log(docRef);
const docSnap = authToken == '' || authToken == null ? null : await getDoc(docRef);
const userRef = authToken == '' || authToken == null ? {} : docSnap.data();console.log(userRef);

export default function Perfil({navigation}){
    

    const [nombre, SetNombre] = React.useState(userRef.nombre);
    const [email, SetEmail] = React.useState(userRef.email);
    const [telefono, SetTelefono] = React.useState('');

    return (
        <View style={{flex: 1}}>
            <ScrollView style={styles.container}>

                <Text style={styles.titulo}>Perfil</Text>

                <Image
                style={styles.logo}
                source={ require('../img/logo.png')}
                />

                <TextInput 
                    style={styles.input}
                    placeholder="Nombre"
                    onChangeText={SetNombre}
                    value={nombre}
                />
                
                <TextInput 
                    style={styles.input}
                    placeholder="Email"
                    onChangeText={SetEmail}
                    value={email}
                />
                
                <TextInput 
                    style={styles.input}
                    placeholder="Teléfono"
                    // onChangeText={onChangeText}
                    // value={text}
                />

                <TouchableOpacity style={styles.buttonGrande}>
                    <Text style={styles.buttonGrandeTexto}>
                        Guardar
                    </Text>
                </TouchableOpacity>
                
                <TouchableOpacity style={styles.buttonGrandeOutline}>
                    <Text style={styles.buttonGrandeOutlineTexto} onPress={() => LogoutFn()}>
                        Cerrar Sesión
                    </Text>
                </TouchableOpacity>
            </ScrollView>

            <Botones navigation={navigation}/>
        </View>
    );
}