import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text  } from "react-native";
import BotonesTienda from "../components/BotonesTienda";

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 800,
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
      detalleHome: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: '#0400b6',
        borderBottomWidth: 1,
        padding: 10,
        marginBottom: 5,
      },
      descripcionItem: {
        fontSize: 16,
      },
      detalleItem:{
        fontSize: 21,
      },
});

export default function HomeTienda({navigation}){
    return (
        <View style={{flex: 1}}>
            <ScrollView style={styles.container}>
                <Text style={styles.titulo}>Inicio</Text>
                
                <View style={styles.detalleHome}>
                    <Text style={styles.descripcionItem}>Productos Registrados:</Text>
                    <Text style={styles.detalleItem}>0</Text>
                </View>

                <View style={styles.detalleHome}>
                    <Text style={styles.descripcionItem}>Ventas Registrados:</Text>
                    <Text style={styles.detalleItem}>0</Text>
                </View>
            </ScrollView>

            <BotonesTienda navigation={navigation}/>
        </View>
    );
}