import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';

import LoginForm from './source/views/LoginForm';
import User from './source/views/User';
import UserTienda from './source/tienda/UserTienda';

const Stack = createStackNavigator();

const HeaderOpt = {
  
    headerStyle: {
      backgroundColor: '#0400b6',
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },

};

const tipoUser = await AsyncStorage.getItem('tipoUser');console.log('tipo user' + tipoUser);
// const tipoUser = 1;

const authToken = await AsyncStorage.getItem('authToken');

export default function App() {

  if(authToken == null || authToken == '')
  {
    return (
        <LoginForm />
    );
  }
  
  if(authToken !== null)
  {

    if(tipoUser == 2)
    {
      return (
          <UserTienda />
      );
    }

    return (
        <User />
    );
    
  }
  
}



