import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeTienda from "./HomeTienda";
import PerfilTienda from "./PerfilTienda";
import ProductosTienda from "./ProductosTienda";
import VentasTienda from "./VentasTienda";

const Stack = createNativeStackNavigator();

export default function UserTienda({navigation}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="HomeTienda" component={HomeTienda}  options={{headerShown: false}} />
                <Stack.Screen name="PerfilTienda" component={PerfilTienda}  options={{headerShown: false}} />
                <Stack.Screen name="ProductosTienda" component={ProductosTienda}  options={{headerShown: false}} />
                <Stack.Screen name="VentasTienda" component={VentasTienda}  options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}