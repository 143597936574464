import React from "react";
import { StyleSheet ,View , Image , TextInput , ScrollView , TouchableOpacity , Text } from "react-native";
import Botones from "../components/Botones";
import Ionicons from '@expo/vector-icons/Ionicons';

const styles = StyleSheet.create({
    container:{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutline: {
        padding: 14,
        textAlign: 'center',
        // backgroundColor: '#ffffff',
        borderColor: '#0400b6',
        borderWidth: 1,
        color: '#0400b6',
        borderRadius: 30,
        marginTop: 30,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeOutlineTexto: {
        color: '#0400b6',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    titulo: {
        fontWeight: 800,
        fontSize: 23.5,
        margin: 20,
        textAlign: 'center',
      },
    logo: {
        width: 100,
        height: 187,
        marginBottom: 80,
        marginRight: 'auto',
        marginLeft: 'auto',
      },
});

export default function Compras({navigation}){
    return (
        <View style={{flex: 1}}>
            <ScrollView style={styles.container}>
                <Text style={styles.titulo}>Compras</Text>

                <Ionicons style={{color:'#0400b6' , textAlign: 'center' , marginTop: 100}} name="pricetags" size={100} color="#0400b6"></Ionicons>
                <Text style={styles.titulo}>Aún no tienes compras registradas</Text>
                
            </ScrollView>

            <Botones navigation={navigation}/>
        </View>
    );
}