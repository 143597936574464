import React from "react";
import { StyleSheet , Text , View , TouchableOpacity , Image , TextInput} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Firebase from "../db/Firebase";
import { doc, setDoc , getDocs , addDoc , collection , Timestamp , query , where, Firestore} from "firebase/firestore";

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tinyLogo: {
      width: 50,
      height: 50,
    },
    logo: {
      width: 100,
      height: 187,
      marginBottom: 80,
    },
    buttonGrande: {
        padding: 14,
        textAlign: 'center',
        backgroundColor: '#0400b6',
        color: '#ffffff',
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '300px',
    },
    buttonGrandeTexto: {
        color: '#ffffff',
        textAlign: 'center',
    },
    buttonPequeno: {
        padding: 9,
        textAlign: 'center',
        color: '#0400b6',
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginTop: 10,
        width: '100%',
        maxWidth: '260px',
    },
    buttonPequenoTexto: {
        color: '#0400b6',
    },
    input: {
        padding: 14,
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    inputBlue: {
      padding: 14,
      textAlign: 'center',
      backgroundColor: '#0400b6',
      color: '#ffffff',
      borderRadius: 30,
      marginBottom: 5,
      width: '100%',
      maxWidth: '300px',
    },
    boton: {
        padding: 14,
        textAlign: 'center',
        borderStyle: 'solid',
        borderColor: '#0400b6',
        borderWidth: 1,
        borderRadius: 30,
        marginBottom: 5,
        width: '100%',
        maxWidth: '300px',
    },
    botonBlue: {
      padding: 14,
      textAlign: 'center',
      backgroundColor: '#0400b6',
      color: '#ffffff',
      borderRadius: 30,
      marginBottom: 5,
      width: '100%',
      maxWidth: '300px',
    }
  });

  async function SetLogin(authToken, tipoUser){
    console.log(tipoUser);
    await AsyncStorage.setItem('authToken', authToken);
    await AsyncStorage.setItem('tipoUser', tipoUser);
    window.location.reload(false);
  }

  

  async function ValidateLogin(data)
  {
    // const [userRef, SetUserRef] = useState('');
    let userRef = '';
    
    const consulta = query(collection(Firebase, "users"), where("email", "==", data.email) , where("password", "==", data.password));

    const querySnapshot = await getDocs(consulta);

    console.log(querySnapshot);
    
    querySnapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      console.log(doc.data());
      userRef = {
        id : doc.id,
        tipo: doc.data().tipo,
      };
    });

    if(userRef == '')
    {
      alert('Por favor verifique los datos');
    }

    if(userRef)
    {console.log(userRef);
      SetLogin(userRef.id , userRef.tipo);
    }
  }

  function Registrar(data)
  {
    try{
      var newDocument = collection(Firebase, "users");
      addDoc(newDocument, {
        email: data.email,
        nombre: data.nombre,
        password: data.password,
        tipo: data.type
      }).then((docRef) => {
        if(data.type == 2)
        {
            // var newTienda = doc(collection(Firebase, "tiendas"));
            var newTienda = collection(Firebase, "tiendas");
            addDoc(newTienda, {
              fechaCreado: Timestamp.fromDate(new Date()),
              name: data.nombreTienda,
              about: data.descripcionTienda,
              picture: 'https://static.vecteezy.com/system/resources/previews/004/439/485/non_2x/store-cartoon-illustration-free-vector.jpg',
            }).then(() => {
              SetLogin( docRef.id ,data.type);
            });
        }
        if(data.type == 1)
        {
          SetLogin(docRef.id ,data.type);
        }
      });
    }catch(error){
      console.log(error);
    }finally{
      
    }
    

  }

  function LoginForm({navigation}){
    
    const [register, SetRegister] = React.useState(false);
    const [nombre, SetNombre] = React.useState('');
    const [email, SetEmail] = React.useState('');
    const [password, SetPassword] = React.useState('');
    const [tipo, SetTipo] = React.useState(1);
    const [nombreTienda, SetNombreTienda] = React.useState('');
    const [descripcionTienda, SetDescripcionTienda] = React.useState('');
    const [imagenTienda, SetImagenTienda] = React.useState('');
    
      return(
          <View style={styles.container}>
              <Image
              style={styles.logo}
              source={ require('../img/logo.png')}
              />
              
               { register ? <>
               
                <TextInput 
                    style={styles.input}
                    onChangeText={SetNombre}
                    placeholder="Nombre"
                    value={nombre}
                />
                <TextInput 
                    style={styles.input}
                    onChangeText={SetEmail}
                    value={email}
                    placeholder="Email"
                />
                <TextInput 
                    secureTextEntry={true}
                    style={styles.input}
                    onChangeText={SetPassword}
                    value={password}
                    placeholder="Contraseña"
                />

              <View style={{flexDirection: 'row' , justifyContent: 'space-around' , maxWidth: '300px'}}>

                <TouchableOpacity style={ tipo == 1 ? styles.botonBlue : styles.boton} onPress={() => SetTipo(1)}>
                  <Text style={ tipo == 1 ? styles.buttonGrandeTexto : {textAlign: 'center'} }>Cliente</Text>
                </TouchableOpacity>
                
                <TouchableOpacity style={ tipo == 2 ? styles.botonBlue : styles.boton } onPress={() => SetTipo(2)}>
                  <Text style={ tipo == 2 ? styles.buttonGrandeTexto : {textAlign: 'center'} }>Tienda</Text>
                </TouchableOpacity>

              </View>

              { tipo == 2 && <>
                <TextInput 
                    style={styles.input}
                    onChangeText={SetNombreTienda}
                    value={nombreTienda}
                    placeholder="Nombre de la tienda"
                />
                <TextInput 
                    multiline
                    numberOfLines={4}
                    style={styles.input}
                    onChangeText={SetDescripcionTienda}
                    value={descripcionTienda}
                    placeholder="Descripción de la tienda"
                />
              </> }
              
              <TouchableOpacity style={styles.buttonGrande} onPress={() => Registrar({
                nombre: nombre,
                email: email,
                password: password,
                type: tipo,
                nombreTienda: nombreTienda,
                descripcionTienda: descripcionTienda,
              })}>
                <Text style={styles.buttonGrandeTexto}>Registrar</Text>
              </TouchableOpacity>
              
              <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetRegister(false)}>
                <Text style={styles.buttonPequenoTexto} >Iniciar Sesión</Text>
              </TouchableOpacity>
              
                </> : <>
                <TextInput 
                  style={styles.input}
                  onChangeText={SetEmail}
                  value={email}
                  placeholder="Email"
              />
              <TextInput 
                  secureTextEntry={true}
                  style={styles.input}
                  onChangeText={SetPassword}
                  value={password}
                  placeholder="Contraseña"
              />
            
            <TouchableOpacity style={styles.buttonGrande} onPress={() => ValidateLogin({
                  email: email,
                  password: password,
                })}>
              <Text style={styles.buttonGrandeTexto}>Iniciar Sesión</Text>
            </TouchableOpacity>
            
            <TouchableOpacity style={styles.buttonPequeno} onPress={() => SetRegister(true)}>
              <Text style={styles.buttonPequenoTexto} >Registro</Text>
            </TouchableOpacity>
               
               </> }
              
          </View>
      );
  }

  export default LoginForm;