import React from "react";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Home from "./Home";
import Tienda from "./Tienda";
import Producto from "./Producto";
import Carrito from "./Carrito";
import Compras from "./Compras";
import Perfil from "./Perfil";

const Stack = createNativeStackNavigator();

export default function User({navigation}){
    return(
        <NavigationContainer>
            <Stack.Navigator>
                <Stack.Screen name="Home" component={Home}  options={{headerShown: false}} />
                <Stack.Screen name="Tienda" component={Tienda} options={{headerShown: false}} />
                <Stack.Screen name="Producto" component={Producto} options={{headerShown: false}} />
                <Stack.Screen name="Carrito" component={Carrito} options={{headerShown: false}} />
                <Stack.Screen name="Compras" component={Compras} options={{headerShown: false}} />
                <Stack.Screen name="Perfil" component={Perfil} options={{headerShown: false}} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}