import React from "react";
import Ionicons from '@expo/vector-icons/Ionicons';
import { SafeAreaView , 
          StyleSheet , 
          FlatList,
          Text , 
          ScrollView,
          Image,
          View , 
          TouchableOpacity , 
          TextInput} from "react-native";
// import { doc, getDoc } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

import Botones from "../components/Botones";
import Firebase from "../db/Firebase";

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#ececec',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputBuscar: {
        // padding: 14,
        // borderRadius: 5,
        // marginBottom: 5,
        // marginTop: 15,
        paddingLeft: 10,
        marginLeft: 'auto',
        width: '90%',
    },
    button: {
        // display: 'inline-block',
        flex : 1,
        borderStyle: 'solid',
        borderColor: '#8a8a8a',
        borderWidth: 1,
        paddingTop: 4,
        paddingRight: 10,
        paddingBottom: 4,
        paddingLeft: 10,
        borderRadius: 5,
        marginRight: 4,
        marginLeft: 4,
    },
    buttonText:{
      color: '#8a8a8a',
    },
    containerSafeArea: {
      flex: 1,
      marginTop: 0,
    },
    gridLista : {
      width: '80%',
      // flexWrap: 'wrap',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      flexGrow: 1,
    },
    itemLista : {
      width: '100%',
      justifyContent: 'center',
      margin: 'auto',
      marginTop: 10,
    },
    imagenLista: {
      width: '100%',
      height: 170,
      borderRadius: 15,
      marginTop: 5,
      marginBottom: 5,
    },
    titulo: {
      fontWeight: 800,
      fontSize: 23.5,
      margin: 20,
    },
    tituloLista: {
      fontWeight: 700,
    },
    descripcionLista: {
      marginBottom: 15,
    },
    botonVer: {
      textAlign: 'right',
    },
    botonVerTexto: {
      color: '#0400b6',
      fontWeight: 700,
    },
    buttonContainer: {
      // flex: 1,
      // flexDirection: 'row',
      width: '80%',
    },
    searchTop: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '80%',
      marginTop: 20,
    },
    searchTopInput: {
      flexDirection: 'row',
      width: '90%',
      borderStyle: 'solid',
      borderColor: '#0400b6',
      borderWidth: 1,
      padding: 5,
      borderRadius: 5,
      marginTop: 'auto',
      marginBottom: 'auto',

    },
    searchIcon: {
        // marginTop: 15,
        // width: 20,
        padding: 5,
        borderRightColor: '#0400b6',
        borderRightWidth: 1,
        borderStyle: 'solid',
    },
    heartIcon: {
        padding: 10,
        backgroundColor: '#0400b6',
        borderRadius: 5,
        marginLeft: 5,
        marginTop: 'auto',
        marginBottom: 'auto',
    },
  });
 
  const DATA = [];
  const querySnapshot = await getDocs(collection(Firebase, "tiendas"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    DATA.push(doc.data());
    console.log(doc.id, " => ", doc.data());
  });
  
    
  const Item = ({item , navigation}) => (
    <View style={styles.itemLista}>
      <TouchableOpacity onPress={() => navigation.navigate('Tienda' , {
        tiendaId: item.id,
        tiendaNombre: item.name,
        tiendaDescripcion: item.about,
      })}>
        <Image source={{ uri: item.picture }} style={styles.imagenLista}/>
      </TouchableOpacity>
      
      <Text style={styles.tituloLista}>{item.name}</Text>
      <Text style={styles.descripcionLista}>{item.about.substring(0,100)}...</Text>

      <TouchableOpacity style = {styles.botonVer}>
        <Text style = {styles.botonVerTexto}>Ver Productos <Ionicons name="arrow-forward" size={20} color="#0400b6"></Ionicons></Text>
      </TouchableOpacity>
    </View>
  );
  
  const Categorias = ({item}) => (
    <TouchableOpacity 
          style={styles.button}>
            <Text style={styles.buttonText}>{item.text}</Text>
    </TouchableOpacity>
  );

  function Home({navigation}){
      return(
        <View style={{flex: 1}}>
          <ScrollView style={styles.containerSafeArea}>
            <View style={styles.container}>
              
              <View style={styles.searchTop}>
                
                <View style={styles.searchTopInput}>
                  <Ionicons style={styles.searchIcon} name="search" size={20} color="#0400b6"/>
                  <TextInput
                    placeholder="Buscar productos o tiendas"
                    style={styles.inputBuscar}
                  />
                </View>
                <TouchableOpacity>
                  <Ionicons style={styles.heartIcon} name="heart" size={20} color="#ffffff"/>
                </TouchableOpacity>
              </View>

            
                <Text style={styles.titulo}>Tiendas</Text>

                <FlatList
                  data={[{ id: 1 , text : 'Todo'} , { id:2 ,  text : 'Uno'}]}
                  keyExtractor={item => item.id}
                  style={styles.buttonContainer}
                  horizontal={true}
                  renderItem={({item}) => <Categorias item={item} />}
                />

                <FlatList
                  data={DATA}
                  renderItem={({item}) => <Item item={item} navigation={navigation}/>}
                  keyExtractor={item => item.id}
                  contentContainerStyle={styles.gridLista}
                  
                />
            
              
            </View>
          </ScrollView>
          <Botones navigation={navigation}/>
        </View >
      );
  }

  export default Home;